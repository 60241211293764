<template>
  <KPage fluid>
    <!-- Barra de busca -->
    <SearchFilter
      v-model="filter"
      ref="search_filter"
      @refresh="onRefresh"
      @submit="onSearchClick"
      @click:report="onReportClick"
    />

    <!--  -->
    <SectionHeader title="Resultados" class="ma-2" />

    <!-- Resultados -->
    <ItemsList
      :items="items"
      :pagination="pagination"
      :loading="loading"
      @click:change-pagination="onChangePagination"
      @click:row="onItemClick"
      @click:add_user="onAddUserClick"
      @click:reset_password="onResetPasswordClick"
      @click:view_logs="onOpenLogsClick"
      @click:edit_user="onEditUserClick"
    />

    <!-- Botão FAB -->
    <FAB @click="add" />

    <!-- Caixa de Diálogo -->
    <DialogAdminPasswordReset ref="password_reset_dialog" />
    <DialogList ref="dialog_list" />
    <DialogUser ref="dialog_user" />
  </KPage>
</template>

<script>
import KPage from "@/components/KPage";
import SectionHeader from "@/components/SectionHeader";
import PaginationBar from "@/components/Pagination/Bar";
import FAB from "@/components/FAB";

import SearchFilter from "./Filter";
import ItemsList from "./ItemsList";

// Dialogs internas
import DialogUser from "../_shared/DialogUser";
import DialogAdminPasswordReset from "../_shared/DialogAdminPasswordReset";
// Dialogs de outros módulos
import DialogList from "@/views/Logs/DialogList";

export default {
  components: {
    KPage,
    PaginationBar,
    SectionHeader,
    FAB,
    //
    SearchFilter,
    ItemsList,
    //
    DialogUser,
    DialogAdminPasswordReset,
    DialogList,
  },

  data() {
    return {
      loading: false,
      page: 1,
      limit: 20,
      filter: {},
    };
  },

  watch: {
    filter(nv, ov) {
      // console.log("watch filter: ", { ...nv }, { ...ov });
    },
  },

  computed: {
    items() {
      return this.$store.getters["people/all"];
    },

    pagination() {
      return this.$store.getters["people/pagination"];
    },

    /**
     *
     */
    searchResume() {
      return this.$store.getters["financials/searchResume"];
    },
  },

  methods: {
    async onRefresh(params) {
      // console.log("atualizou");
      this.filter = params;
      await this.$nextTick();
      this.onLoad(this.page, this.limit, params);
    },

    async onChangePagination({ page, limit }) {
      // console.log("trocou pagination", page, limit);
      this.page = page;
      this.limit = limit;

      await this.$nextTick();

      this.onLoad(page, limit, this.filter);
    },

    onSearchClick(params) {
      // console.log("search params", params);
      this.filter = params;
      this.onLoad(undefined, undefined, params);
    },

    async onReportClick({ ...params }) {
      try {
        // this.$message.show("Relatório está sendo gerado");
        this.$loading.show("Aguarde enquanto geramos o relatório");

        params.download = true;
        params.limit = 10000;
        params.showUser = true;

        // setTimeout(() => {
        //   this.$message.show("pegadinha do malandro");
        // }, 2000);
        await this.$api.download("/people", params, "relatorio.xlsx");
        this.$loading.hide();
      } catch (error) {
        this.$loading.hide();
        this.$message.serverError(error);
      }
    },

    async onSave() {
      await this.$nextTick();
      this.onLoad(this.page, this.limit, this.filter);
    },

    /**
     * Ao clicar no item da lista
     */
    onItemClick(item) {
      if (item.billet_text_line) {
        this.$refs["dialog_view_charge"].open(item);
      } else {
        this.$refs["dialog_save"].open({ ...item });
      }
    },

    /**
     * Carrega as informações
     */
    async onLoad(page = 1, limit = 20, params = null) {
      try {
        await this.$nextTick();
        const payload = {
          full: true,
          showContacts: true,
          showUser: true,
          page,
          limit,
          ...params,
        };
        // console.log("no onload", payload);

        if (this.loading) {
          // console.log("já tem um carregando. ", payload);
          return;
        }

        this.loading = true;

        await Promise.all([this.$store.dispatch("people/refresh", payload)]);

        // this.$loading.hide();
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$message.serverError(error);
        // this.$loading.hide();
        console.log(error);
      }
    },

    /**
     * Injeta os parâmetros da url na
     */
    injectQueryParams() {
      // Parâmetro da url
      const query = this.$route.query;
      // Lista branca de parâmetros
      const whiteList = ["q", "legal_nature", "is_user", "role"];

      // Dados filtrados
      const filter = {};

      // console.log("query", query);

      // Se não tiver mês selecionado, selecione o mês atual
      // if (!query.month) filter.month = moment().format("YYYY-MM");

      // Filtra os parâmetros da url de acordo com os valores da lista branca
      whiteList
        .filter(white => query[white])
        .forEach(white => {
          filter[white] = query[white];
        });

      // Injeta os parâmetros da url nas informações do filtro
      // this.$refs["search_filter"].init(filter);
      this.filter = filter;
    },

    /**
     * Abre o dialog como cadastro
     */
    add() {
      this.$router.push("/people/add");
    },

    /**
     * Ao clicar na linha
     */
    onItemClick(person) {
      console.log("abriw", person);
      this.$router.push("/people/view/" + person.id);
    },

    /**
     * Abre a caixa de dialog
     */
    async onResetPasswordClick(data) {
      this.$refs["password_reset_dialog"].open(data);
    },
    /**
     * Abre o dialog dos logs
     */
    async onOpenLogsClick(person) {
      this.$refs.dialog_list.open(
        "Logs Relacionados",
        null,
        "user",
        person.user && person.user.id
      );
    },

    /**
     * Abre o dialog dos logs
     */
    async onAddUserClick(person) {
      try {
        const emails = this.getPersonEmails(person);

        if (!emails.length)
          throw {
            message: "Esta pessoa não possui e-mails cadastrados",
          };

        person.emails = emails;
        this.$refs.dialog_user.openToAdd(person);
      } catch (error) {
        this.$message.error(error.message);
      }
    },

    /**
     * Abre o dialog dos logs
     */
    async onEditUserClick(person) {
      try {
        const emails = this.getPersonEmails(person);

        if (!emails.length)
          throw {
            message: "Esta pessoa não possui e-mails cadastrados",
          };

        person.emails = emails;
        this.$refs.dialog_user.openToEdit(person);
      } catch (error) {
        this.$message.error(error.message);
      }

      // if(person.contacts)
    },

    getPersonEmails(person) {
      let emails = [];

      if (person && person.contacts && person.contacts.length) {
        emails = person.contacts
          .filter(contact => contact.contact_type.slug === "email")
          .map(contact => contact.value);
      }

      return emails;
    },
  },

  mounted() {
    this.injectQueryParams();
  },

  created() {},
};
</script>

<style>
</style>
