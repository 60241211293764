<template>
  <v-select
    item-text="name"
    return-object
    :dense="dense"
    :items="items"
    v-model="localValue"
    v-bind="$attrs"
  >
    <template v-slot:selection="{ item, index }">
      <v-chip :small="dense" v-if="index < maxChips">
        <span>{{ item.name }}</span>
      </v-chip>
      <!-- mx:{{maxChips}}/i:{{index}}/l:{{value.length}} -->
      <span v-if="index == maxChips" class="grey--text caption"
        >(+{{ value.length - maxChips }})</span
      >
    </template>
  </v-select>
</template>

<script>
export default {
  name: "roles-selector",
  props: {
    value: Array,
    dense: Boolean,
    maxChips: {
      type: [String, Number],
      default: 1,
      validator: val => parseInt(val) == val && val >= 0
    }
  },
  data() {
    return {
      localValue: []
    };
  },
  watch: {
    value(value) {
      // console.log("value atualizado", value);
      this.localValue = value;
    },
    localValue(value) {
      // console.log("localvalue atual", value);
      this.$emit("input", value);
    }
  },
  computed: {
    itemValue() {
      if (this.returnIds) return "id";
      else if (this.returnSlugs) return "slug";
    },
    items() {
      return this.$store.getters["roles/all"].filter(
        item => !this.ignore.includes(item.slug)
      );
    },
    list() {
      return this.items.map(item => item.slug);
    },
    ignore() {
      return ["guest", "logged"];
    }
  },
  methods: {
    async load() {
      try {
        this.$loading.show();
        await Promise.all([this.$store.dispatch("roles/all")]);
        this.$loading.hide();
      } catch (error) {
        this.$message.serverError(error);
        this.$loading.hide();
      }
    }
  },

  mounted() {
    this.load();
    this.localValue = this.value;
  }
};
</script>

<style lang="scss" scoped>
</style>
