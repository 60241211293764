<template>
  <div class="v-static">
    <label>{{label}}</label>
    <div class="text-truncate">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "v-static",
  props: { label: { type: String, required: true } }
};
</script>

<style scoped>
.grey2 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.54);
}

.v-static {
  margin-top: 4px;
  background-color: rgba(0, 0, 0, 0.025);
  padding: 0 4px;
  border-radius: 2px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.54);
  display: grid;
}
.v-static > label {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
  margin: 0;
  height: 15px;
  display: block;
}

.v-static > div {
  height: 30px;
  font-size: 16px;
  padding: 4px 0;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.54); */
  line-height: 20px;
  color: rgba(0, 0, 0, 0.54);
}
</style>