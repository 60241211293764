<template>
  <KPage>
    <KForm ref="form">
      <v-row class="my-4">
        <!--  -->
        <v-col cols="12">
          <h2 class="title mb-3 font-weight-light">Dados Pessoais</h2>
        </v-col>
        <!-- Nome Completo -->
        <v-col cols="12" class="py-0">
          <v-text-field
            v-bind="input"
            :rules="[$validation.isRequired]"
            :error-messages="errors.name"
            label="Nome Completo"
            persistent-hint
            v-model="form.name"
            required
          ></v-text-field>
        </v-col>

        <!-- Natureza Legal -->
        <v-col cols="12" sm="4" class="py-0">
          <v-select
            class="mt-0"
            dense
            label="Natureza Legal"
            :items="legalNatures"
            item-text="label"
            item-value="value"
            v-bind="input"
            v-model="form.legal_nature"
          />
        </v-col>

        <!-- Se for pessoa física -->
        <template v-if="form.legal_nature == 'F'">
          <!-- CPF -->
          <v-col cols="12" sm="4" class="py-0">
            <KInputMasked
              label="CPF"
              mask="999.999.999-99"
              :rules="[$validation.isCPF]"
              :error-messages="errors.cpf"
              v-bind="input"
              v-model="form.cpf"
            />
          </v-col>

          <!-- Documento Profissional -->
          <v-col cols="12" sm="4" class="py-0">
            <v-text-field
              v-bind="input"
              label="Documento Profissional"
              hint="Número do CREA, OAB etc"
              persistent-hint
              v-model="form.professional_document"
              required
            ></v-text-field>
          </v-col>
        </template>

        <!-- Se for pessoa jurídica -->
        <template v-if="form.legal_nature == 'J'">
          <!-- CNPJ -->
          <v-col cols="12" sm="4" class="py-0">
            <KInputMasked
              v-bind="input"
              :rules="[$validation.isCNPJ]"
              label="CNPJ"
              mask="99.999.999/9999-99"
              :error-messages="errors.cnpj"
              persistent-hint
              v-model="form.cnpj"
              required
            />
          </v-col>

          <!-- Inscrição Estadual -->
          <v-col cols="12" sm="4" class="py-0">
            <v-text-field
              v-bind="input"
              label="Inscrição Estadual"
              persistent-hint
              v-model="form.state_registration"
              required
            ></v-text-field>
          </v-col>
        </template>
      </v-row>
    </KForm>

    <!-- Endereços -->
    <v-row class="my-0">
      <v-col cols="12" class="py-0">
        <SectionHeader
          title="Endereços"
          class="my-1"
          :actions="[
            {
              icon: 'mdi-plus',
              eventName: 'click:add',
              label: 'Cadastrar novo endereço',
            },
          ]"
          @click:add="onAddressAddButtonClick"
        ></SectionHeader>
      </v-col>
      <v-col cols="12" class="py-0">
        <AddressList
          v-if="addresses.length > 0"
          :items="addresses"
          :showEdit="isEditing"
          @click:edit="onAddressEditButtonClick($event)"
          @click:delete="onAddressDeleteButtonClick($event)"
        />
      </v-col>
      <v-col cols="12" class="py-0">
        <KAlert v-if="addresses.length === 0" info>
          Nenhum endereço cadastrado
        </KAlert>
      </v-col>
    </v-row>

    <!-- Contatos -->

    <v-row class="my-0">
      <v-col cols="12" class="py-0">
        <SectionHeader
          title="Contatos"
          class="mb-1 mt-8"
          :actions="[
            {
              icon: 'mdi-plus',
              eventName: 'click:add',
              label: 'Cadastrar novo contato',
            },
          ]"
          @click:add="onContactAddButtonClick"
        ></SectionHeader>
      </v-col>
      <v-col cols="12" class="py-0">
        <ContactList
          v-if="contacts.length > 0"
          :items="contacts"
          :showEdit="isEditing"
          @click:edit="onContactEditButtonClick"
          @click:delete="onContactDeleteButtonClick"
        />
      </v-col>

      <v-col cols="12" class="py-0">
        <KAlert v-if="contacts.length === 0" info>
          Nenhum contato cadastrado
        </KAlert>
      </v-col>
    </v-row>

    <!-- Botão de salvar -->
    <v-row justify="center" class="mx-0 my-10">
      <v-btn
        v-if="isEditing"
        color="primary"
        rounded
        elevation="0"
        @click="save()"
        :block="$vuetify.breakpoint.xsOnly"
        class="neutral--text ma-2"
        >Salvar Alterações</v-btn
      >
      <v-btn
        v-if="!isEditing"
        color="primary"
        rounded
        elevation="0"
        @click="save()"
        :block="$vuetify.breakpoint.xsOnly"
        class="neutral--text ma-2"
        >Cadastrar</v-btn
      >
    </v-row>

    <!-- Dialog da lista de endereços -->
    <AddressListDialog
      ref="address-list-dialog"
      @click:insert="insertAddressOnStore"
      @click:add="insertAddressOnStore"
      @click:edit="editAddressOnStore"
    />

    <!-- Dialog da lista de endereços -->
    <ContactListDialog
      ref="contact-list-dialog"
      @click:insert="insertContactOnStore"
      @click:add="insertContactOnStore"
      @click:edit="editContactOnStore"
    />
  </KPage>
</template>

<script>
import KPage from "@/components/KPage";
import KForm from "@/components/KForm";
import KAlert from "@/components/KAlert";
import KInputMasked from "@/components/KInput/Masked";
import SectionHeader from "@/components/SectionHeader";

import AddressList from "./AddressList";
import ContactList from "./ContactList";

import AddressListDialog from "./AddressList/dialog.vue";
import ContactListDialog from "./ContactList/dialog.vue";

export default {
  name: "page-save",
  components: {
    KPage,
    KForm,
    KAlert,
    SectionHeader,
    KInputMasked,

    AddressList,
    ContactList,

    AddressListDialog,
    ContactListDialog
  },

  // directives: { mask },

  data: () => ({
    form: {
      id: null,
      name: null,
      email: null,
      legal_nature: "F",
      cpf: null,
      professional_document: null,
      cnpj: null,
      state_registration: null,
      roles: [],
      addresses: [],
      contacts: []
    },
    addresses: [],
    contacts: [],
    errors: {},
    loading: false,
    input: {
      dense: true,
      outlined: true
    }
  }),

  computed: {
    legalNatures: () => [
      { value: "F", label: "Pessoa Física" },
      { value: "J", label: "Pessoa Jurídica" }
    ],

    isEditing() {
      return !!this.$route.params.id;
    },
    payload() {
      const payload = {
        name: this.form.name,
        legal_nature: this.form.legal_nature
      };

      if (this.form.legal_nature === "F") {
        payload.cpf = this.form.cpf;
        payload.professional_document = this.form.professional_document;
      }

      if (this.form.legal_nature === "J") {
        payload.cnpj = this.form.cnpj;
        payload.state_registration = this.form.state_registration;
      }

      if (!this.isEditing) {
        payload.addresses = this.addresses;
        payload.contacts = this.contacts.map(contact => ({
          contact_type_id: contact.contact_type_id,
          value: contact.value,
          description: contact.description
        }));
      } else {
        payload.id = this.form.id;
      }

      return payload;
    }
  },

  methods: {
    ////////////
    //
    //  USUÁRIO
    //
    ////////////

    /**
     *
     */
    async load() {
      try {
        this.$loading.show();
        const personId = this.$route.params.id;
        const { data } = await this.$api.get(`/people/${personId}`);

        this.addresses = data.data.addresses;
        this.contacts = data.data.contacts;
        this.form = data.data;
        this.$app.title(`${data.data.name}`);
        this.$loading.hide();
      } catch (error) {
        this.$loading.hide();
        this.$message.serverError(error);
      }
    },

    /**
     *
     */
    async save() {
      try {
        if (this.$refs["form"].validate()) {
          this.$loading.show();

          const user = await this.$store.dispatch("people/save", {
            payload: this.payload,
            editing: this.isEditing
          });

          this.$message.success("Dados salvos com sucesso");
          this.$loading.hide();
          this.$router.push(`/people`);
        }
      } catch (error) {
        this.$loading.hide();
        this.errors = this.$message.serverError(error);
      }
    },

    ////////////
    //
    //  ENDEREÇOS
    //
    ////////////

    /**
     * Insere o endereço cadastrado no dialog no store
     *
     * @param {*} address
     */
    insertAddressOnStore(address) {
      this.addresses.push(address);
    },

    /**
     * Edita o endereço no store de acordo com os dados alterados no dialog
     * @param {*} address
     */
    editAddressOnStore(address) {
      const index = this.form.addresses.findIndex(
        item => address.id === item.id
      );
      this.$set(this.form.addresses, index, address);
    },

    /**
     * Ao clicar no botão de adicionar endereço
     */
    onAddressAddButtonClick() {
      if (this.isEditing) {
        this.$refs["address-list-dialog"].openToAdd(this.form.id);
      } else {
        this.$refs["address-list-dialog"].openToGetData();
      }
    },

    /**
     *
     */
    onAddressEditButtonClick({ item, index }) {
      if (this.isEditing) {
        this.$refs["address-list-dialog"].openToEdit(item);
      }
    },

    /**
     *
     */
    async onAddressDeleteButtonClick({ index, item }) {
      if (this.isEditing) {
        try {
          const result = await this.$api.delete(
            `/people/${item.person_id}/addresses/${item.id}`
          );
          this.$delete(this.addresses, index);
        } catch (error) {
          this.$message.serverError(error);
        }
      } else {
        this.$delete(this.addresses, index);
      }
    },

    ////////////
    //
    //  CONTATOS
    //
    ////////////

    /**
     * Insere o contato cadastrado no dialog no store
     *
     * @param {*} contact
     */
    insertContactOnStore(contact) {
      this.contacts.push(contact);
    },

    /**
     * Edita o contato no store de acordo com os dados alterados no dialog
     * @param {*} contact
     */
    editContactOnStore(contact) {
      const index = this.form.contacts.findIndex(
        item => contact.id === item.id
      );

      this.$set(this.form.contacts, index, contact);
    },

    /**
     * Ao clicar no botão de adicionar contato
     */
    onContactAddButtonClick(item) {
      if (this.isEditing) {
        this.$refs["contact-list-dialog"].openToAdd(this.form.id);
      } else {
        this.$refs["contact-list-dialog"].openToGetData();
      }
    },

    /**
     *
     */
    onContactEditButtonClick({ item, index }) {
      if (this.isEditing) {
        this.$refs["contact-list-dialog"].openToEdit(item);
      }
    },

    /**
     *
     */
    onContactDeleteButtonClick({ index, item }) {
      if (this.isEditing) {
        this.$api.delete(`/people/${item.person_id}/contacts/${item.id}`);
      }

      this.$delete(this.contacts, index);
    },

    //////////////
    // Events
    //////////////

    /**
     *
     */
    reset() {
      this.form = {};

      this.addresses = [];
      this.contacts = [];
      this.errors = {};
      this.loading = false;
    }
  },

  mounted() {
    if (this.isEditing) {
      this.load();
    }
  }
};
</script>

<style>
</style>
