<!-- 

  <KInputMasked
    v-model="phone"
    label="Telefone"
    mask="(99) 9 9999-9999"
    validate-mask
    return-masked
    @finished="onFinished"
  />
 -->

<template>
  <v-text-field
    v-bind="$attrs"
    v-model="model"
    inputmode="numeric"
    :maxLength="maxLength"
    @keydown="onKeyDown"
    :rules="applyRules"
  />
</template>

<script>
import { mask, unMask } from "remask";

export default {
  name: "k-input-masked",

  props: {
    value: String,
    mask: [String, Array],
    rules: { type: Array, default: () => [] },
    // Define se o valor deve ser retornado com a máscara
    returnMasked: Boolean,
    // Define se deve-se validar se o valor está batendo com a máscara
    validateMask: Boolean
  },

  data() {
    return {
      model: null
    };
  },

  watch: {
    value(newVal, oldVal) {
      // console.log("watch value:", newVal, oldVal);
      // if (!oldVal) {
      this.model = this.applyMask(newVal);
      // }
      // this.model = this.maskedValue;
      // this.model = newVal;
    },
    model(value, oldValue) {
      // console.log("watch model:", value, oldValue);
      // Define como null se estiver vazio
      // if (!value) value = null;
      // console.log("emitInput", this.emitInput);
      //
      this.$emit("input", this.emitInput);
      this.model = this.maskedValue;

      //
      if (this.maskedValue && this.maskedValue.length === this.maxLength) {
        this.$emit("finished", this.emitInput);
      }
    }
  },

  computed: {
    /**
     * Gera o valor com máscara
     */
    maskedValue() {
      if (this.model) {
        // console.log("entrou no masked", mask(unMask(this.model), this.mask));
        // return mask(unMask(this.model), this.mask);
        return this.applyMask(this.model);
      }
      return null;
    },

    /**
     * Gera o valor sem máscara
     */
    unMaskedValue() {
      if (this.model) return unMask(this.model);
      return null;
    },

    /**
     * Verifica se deve usar ou não a máscara e retorna o valor correto
     */
    emitInput() {
      // console.log(
      //   "emitInput",
      //   this.returnMasked,
      //   this.maskedValue,
      //   this.unMaskedValue
      // );
      if (this.returnMasked) return this.maskedValue;
      return this.unMaskedValue;
    },

    /**
     * Verifica se o valor com a máscara é válido
     */
    isValid() {
      if (this.maskedValue) {
        if (typeof this.mask === "string") {
          return this.maskedValue.length === this.maxLength;
        } else if (Array.isArray(this.mask)) {
          return this.mask.some(
            mask => mask.length === this.maskedValue.length
          );
        }
      }

      return false;
    },

    /**
     * Aplica as regras vindas do formulário e mescla com as regras definidas
     * no componente, desde que seja definido o atributo validateMask
     */
    applyRules() {
      const rules = this.rules;

      if (this.validateMask) {
        // Valida se o valor está no formato da máscara. Se estiver vazio, pula
        const isValid = () =>
          !this.model || this.isValid
            ? true
            : "Formato " + JSON.stringify(this.mask);
        rules.push(isValid);
      }

      return rules;
    },

    /**
     *
     */

    /**
     * Calcula o tamanho máximo do campo de texto
     */
    maxLength() {
      if (this.mask) {
        // Se for string, retorne-o
        if (typeof this.mask === "string") return this.mask.length;
        // Se for array, verifique o maior
        else if (Array.isArray(this.mask)) {
          return this.mask.reduce(
            (last, curr) => (curr.length > last ? curr.length : last),
            0
          );
        }
      }

      return "";
    }
  },

  methods: {
    /**
     * Aplica uma máscara
     */
    applyMask(value) {
      if (value) return mask(unMask(value), this.mask);
      return null;
    },
    /**
     * Ativado quando apertar a tecla.
     * Remove os caracters não numéricos
     * Permite carac
     */
    onKeyDown(e) {
      const key = e.key;
      if (/^[a-z]{1}$/i.test(key) && !e.ctrlKey) {
        // console.log("prevent nessa porra", key);
        e.preventDefault();
      }
    }
  },

  mounted() {
    //
    if (this.value) this.model = this.value;
    else this.model = null;
  }
};
</script>

<style>
</style>
