<!-- 

    <ZipCodeField
      v-model="form.zipcode"
      @loaded="onLoaded"
      @error="onError"
    />

    function onLoaded(data){
      this.form = data
    }

    function onError(error){
      alert(error.message)
    }

-->
<template>
  <v-text-field
    ref="field"
    append-icon="mdi-magnify"
    :error-messages="errors"
    :loading="loading"
    v-model="model"
    v-mask="'##.###-###'"
    v-bind="$attrs"
    @keypress.enter="search"
    @click:append="search"
    @change="() => (errors = [])"
  ></v-text-field>
</template>

<script>
import Axios from "axios";
import { mask } from "vue-the-mask";
mask.masked = false;

export default {
  name: "zip-code-field",

  props: {
    value: [String, Number]
  },

  directives: { mask },

  data: () => ({
    model: null,
    loading: false,
    errors: [],
    error: false
  }),

  watch: {
    model(val, old) {
      // console.log("watch model", val, old);
      this.$emit("input", val);
    },
    value(val, old) {
      // console.log("watch value", val, old);
      // if (!val)
      this.model = val;
      // this.$nextTick(() => {});
    }
  },

  methods: {
    async search(v) {
      try {
        const zipcode = String(this.model).replace(/[^0-9]+/g, "");

        if (!zipcode) {
          this.errors.push("Informe um CEP");
        }

        if (zipcode.length !== 8) {
          this.errors.push("CEP inválido");
        }

        this.loading = true;

        const url = `https://viacep.com.br/ws/${zipcode}/json/`;

        const { data } = await Axios.get(url);

        if (data.erro) {
          throw new Error("CEP não encontrado");
        }

        this.loading = false;

        this.errors = [];
        this.error = false;

        this.$emit("loaded", {
          street: data.logradouro,
          number: null,
          district: data.bairro,
          complement: data.complemento,
          city: data.localidade,
          state: data.uf
        });
      } catch (error) {
        this.loading = false;
        this.errors = [error.message];
        this.$emit("error", error);
        this.$emit("loaded", {
          street: null,
          number: null,
          district: null,
          complement: null,
          city: null,
          state: null
        });
      }
    }
  },

  mounted() {
    console.log("mounted");
    this.model = this.value;
  }
};
</script>

<style>
</style>
