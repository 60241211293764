<template>
  <div>
    <template v-if="person && person.user && person.user.roles">
      <KChip v-for="role in person.user.roles" :key="role.name" pink>{{
        role.name
      }}</KChip>
    </template>
    <!-- Se o usuário está ativo -->
    <KChip v-if="person && person.user && person.user.is_active" blue
      >Usuário Ativo</KChip
    >
    <KChip v-else-if="person && person.user && !person.user.is_active"
      >Usuário Inativo</KChip
    >
  </div>
</template>

<script>
import KChip from "@/components/KChip";
export default {
  components: {
    KChip
  },
  props: {
    person: Object
  }
};
</script>

<style>
</style>
