<template>
  <v-card outlined>
    <v-list class="py-0">
      <contact-list-item
        v-for="(item, index) in items"
        v-bind="item"
        :key="item.id"
        :showEdit="showEdit"
        @click:item="onEditClick(index, item)"
        @click:edit="onEditClick(index, item)"
        @click:delete="onDeleteClick(index, item)"
      ></contact-list-item>
    </v-list>
  </v-card>
</template>

<script>
import ContactListItem from "./item.vue";

export default {
  name: "contact-list",
  components: {
    ContactListItem
  },
  props: {
    items: { type: Array, default: () => [] },
    showEdit: Boolean
  },
  data: () => ({}),

  methods: {
    onEditClick(index, item) {
      this.$emit("click:edit", { index, item });
    },
    onDeleteClick(index, item) {
      this.$emit("click:delete", { index, item });
    }
  }
};
</script>

<style>
</style>
