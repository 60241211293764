<template>
  <div>
    <v-list-item two-line @click.stop="handleClick">
      <v-list-item-content>
        <v-list-item-title>{{ description }}</v-list-item-title>
        <v-list-item-subtitle>
          <AddressFormatter
            :street="street"
            :number="number"
            :complement="complement"
            :district="district"
            :city="city"
            :state="state"
            :zipcode="zipcode"
          />
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-row>
          <v-btn v-if="showEdit" @click.stop="handleEditClick" icon
            ><v-icon>mdi-pencil</v-icon></v-btn
          >
          <v-btn @click.stop="handleDeleteClick" icon
            ><v-icon>mdi-delete</v-icon></v-btn
          >
        </v-row>
      </v-list-item-action>
    </v-list-item>
    <v-divider></v-divider>
  </div>
</template>

<script>
import AddressFormatter from "@/components/AddressFormatter";
export default {
  name: "address-list-item",

  components: {
    AddressFormatter
  },

  props: {
    id: Number,
    description: { type: String, default: "" },
    street: { type: String, default: "" },
    number: { type: String, default: "" },
    complement: { type: String, default: "" },
    district: { type: String, default: "" },
    city: { type: String, default: "" },
    state: { type: String, default: "" },
    zipcode: { type: String, default: "" },
    latitude: { type: String, default: "" },
    longitude: { type: String, default: "" },

    //
    showEdit: Boolean
  },

  methods: {
    handleClick() {
      this.$emit("click:item");
    },
    handleEditClick() {
      this.$emit("click:edit");
    },
    handleDeleteClick() {
      this.$emit("click:delete");
    }
  }
};
</script>

<style>
</style>
