<template>
  <v-card outlined rounded class="mx-2 overflow-hidden rounded-lg">
    <v-expansion-panels flat v-model="isOpened">
      <v-expansion-panel>
        <v-expansion-panel-header class="py-0">
          <v-toolbar flat color="transparent">
            <v-btn icon @click.stop="onRefreshClick">
              <v-icon> mdi-refresh </v-icon>
            </v-btn>

            <v-badge
              :content="payloadLength"
              :value="payloadLength"
              color="accent"
            >
              Filtros
            </v-badge>
          </v-toolbar>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <!--  -->
          <KForm ref="form">
            <v-row class="ma-0">
              <!-- Nome -->
              <v-col class="py-0" cols="6" md="3">
                <v-text-field
                  dense
                  clearable
                  outlined
                  label="Nome"
                  persistent-hint
                  required
                  v-model="form.q"
                ></v-text-field>
              </v-col>

              <!-- Natureza Legal -->
              <v-col class="py-0" cols="6" md="3">
                <v-select
                  dense
                  clearable
                  outlined
                  label="Natureza Legal"
                  persistent-hint
                  required
                  item-text="name"
                  item-value="key"
                  :items="legalNatureItems"
                  v-model="form.legal_nature"
                ></v-select>
              </v-col>

              <!--  -->
              <v-col class="py-0" cols="6" md="3">
                <v-select
                  clearable
                  dense
                  outlined
                  item-value="slug"
                  item-text="name"
                  label="Permissões"
                  :items="roleItems"
                  v-model="form.role"
                />
              </v-col>

              <!--  -->
              <v-col class="py-0" cols="6" md="3">
                <v-switch
                  class="my-0"
                  inset
                  hide-details
                  label="Somente Usuários"
                  v-model="form.is_user"
                ></v-switch>
              </v-col>

              <!-- Botões -->
              <v-col class="mt-2 py-0 pb-2" cols="12">
                <v-row
                  class="
                    my-0
                    flex
                    justify-center
                    align-center align-items-center
                  "
                >
                  <v-btn
                    depressed
                    rounded
                    color="neutral"
                    class="align-self-center mx-1"
                    @click="onClick"
                  >
                    <v-icon left>mdi-filter</v-icon>

                    Aplicar Filtro
                  </v-btn>
                  <v-btn
                    depressed
                    rounded
                    color="neutral"
                    class="align-self-center mx-1"
                    @click="onReportClick"
                  >
                    <v-icon left>mdi-microsoft-excel</v-icon>

                    Exportar
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </KForm>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import Storage from "@/plugins/Storage";

import KForm from "@/components/KForm";

import RolesSelector from "../_shared/RolesSelector";

export default {
  components: { KForm, RolesSelector },

  props: {
    value: Object
  },

  data() {
    return {
      errors: {},
      form: {},
      info: {},
      isOpened: false
    };
  },

  watch: {
    value(val) {
      this.form = val;

      this.$set(this.form, "status_id", val.status_id ? +val.status_id : null); // converte o status_id pra int
    },
    isOpened(val) {
      if (val === 0) {
        Storage.set("people-filter-is-opened", true);
      } else {
        Storage.set("people-filter-is-opened", false);
      }
    }
  },

  computed: {
    legalNatureItems() {
      return this.$store.getters["people/legalNatures"];
    },

    roleItems() {
      return this.$store.getters["roles/all"].filter(
        item => !["logged", "guest"].includes(item.slug)
      );
    },

    /**
     * Devolve a busca removida os itens vazios
     */
    payload() {
      try {
        const payload = {};

        Object.keys(this.form)
          .filter(key => this.form[key])
          .forEach(key => {
            payload[key] = this.form[key];
          });

        return payload;
      } catch (error) {
        return {};
      }
    },
    payloadLength() {
      return Object.keys(this.payload).length;
    }
  },

  methods: {
    onClick() {
      this.$router.replace({ query: this.payload });
      this.$emit("submit", this.payload);
    },
    onReportClick() {
      this.$emit("click:report", this.payload);
    },
    onRefreshClick() {
      this.$emit("refresh", this.payload);
    }
  },

  mounted() {
    this.$store.dispatch("roles/all");
  },

  created() {
    // Pega a informação salva no storage se é para mostrar o filtro aberto ou fechado
    const isOpened = Storage.get("people-filter-is-opened");

    // Caso esteja true, marque isOpened com 0, que é o primeiro índice do expansion.
    // Caso não seja true, deixe a variável como undefined, ou seja, não abre o expansion
    if (isOpened) this.isOpened = 0;
    else this.isOpened = undefined;
  }
};
</script>

<style>
</style>
