<template>
  <KDialog
    :visible="visible"
    :loading="loading"
    :title="title"
    :actions="dialogActions"
    @click:edit="save"
    @click:add="save"
    @click:get="insert"
    @click:close="close"
  >
    <KForm ref="form">
      <v-container>
        <v-row class="my-4">
          <!-- Descrição -->
          <v-col cols="12" class="">
            <v-text-field
              outlined
              dense
              label="Descrição"
              hint="Defina uma descrição para este contato"
              persistent-hint
              :error-messages="errors.description"
              v-model="form.description"
              required
            ></v-text-field>
          </v-col>

          <!-- Tipo do Contato -->
          <v-col cols="12" sm="6" class="">
            <v-select
              outlined
              dense
              label="Tipo do Contato"
              persistent-hint
              item-value="id"
              item-text="name"
              :items="contactTypes"
              :rules="[$validation.isRequired]"
              :error-messages="errors.contact_type_id"
              v-model="form.contact_type_id"
              @change="onChangeContactType"
            ></v-select>
          </v-col>

          <!-- Valor -->
          <v-col cols="12" sm="6" class="">
            <KInputMasked
              v-if="form.contact_type && form.contact_type.mask"
              outlined
              dense
              return-masked
              persistent-hint
              :mask="form.contact_type && form.contact_type.mask"
              :rules="[$validation.isRequired]"
              :label="form.contact_type && form.contact_type.placeholder"
              :error-messages="errors.value"
              :hint="form.contact_type && form.contact_type.hint"
              v-model="form.value"
            />
            <v-text-field
              v-else
              outlined
              dense
              :rules="[$validation.isRequired, isValidValue]"
              :label="form.contact_type && form.contact_type.placeholder"
              :hint="form.contact_type && form.contact_type.hint"
              persistent-hint
              :error-messages="errors.value"
              v-model="form.value"
            />
          </v-col>
        </v-row>
      </v-container>
    </KForm>
  </KDialog>
</template>

<script>
import KForm from "@/components/KForm";
import KDialog from "@/components/KDialog";
import KInputMasked from "@/components/KInput/Masked";
import KInputSelect from "@/components/KInput/Select";
import { isEmail, isUrl } from "@/plugins/utils";

export default {
  components: {
    KForm,
    KDialog,
    KInputMasked,
    KInputSelect
  },

  data() {
    return {
      // dialog
      visible: false,
      title: "Cadastrar Contato",
      loading: false,
      //
      form: {},
      errors: {},
      mode: null
    };
  },
  watch: {},

  computed: {
    dialogActions() {
      switch (this.mode) {
        case "edit":
          return [
            {
              label: "Salvar Alterações",
              eventName: "click:edit"
            }
          ];
        case "add":
          return [
            {
              label: "Cadastrar",
              eventName: "click:add"
            }
          ];
        case "get":
          return [
            {
              label: "Inserir",
              eventName: "click:get"
            }
          ];
      }
    },
    contactTypes() {
      return this.$store.getters["contact_types/all"];
    }
  },

  methods: {
    /**
     * Devolve os dados para a página pai sem salvar
     */
    insert() {
      if (this.$refs["form"].validate()) {
        this.form.contact_type = this.contactTypes.find(
          item => this.form.contact_type_id == item.id
        );

        this.$emit("click:insert", this.form);
        this.clear();
        this.$refs["form"].resetValidation();
        this.close();
      }
    },

    /**
     * Salva os dados do contato no banco de dados e devolve o evento
     * para o pai atualizar o store
     */
    async save() {
      try {
        this.errors = {};

        if (this.$refs["form"].validate()) {
          this.loading = true;

          if (this.mode === "edit") {
            const contact = await this.$api.put(
              `/people/${this.form.person_id}/contacts/${this.form.id}`,
              this.form
            );

            // console.log("editado no dialog", contact.data.data.contact_type);

            this.$emit("click:edit", contact.data.data);
          } else if (this.mode === "add") {
            const contact = await this.$api.post(
              `/people/${this.form.person_id}/contacts`,
              this.form
            );

            // console.log("cadastro", contact.data.data);
            this.$emit("click:add", contact.data.data);
          }

          this.$message.success("Dados salvos com sucesso");
          this.loading = false;
          this.close();
        }
      } catch (error) {
        this.errors = this.$message.serverError(error);
        this.loading = false;
      }
    },

    /**
     * Atualiza o valor do contact_type dentro de form
     * ao detectar uma alteração no tipo do contato
     */
    onChangeContactType(contact_type_id) {
      this.$set(
        this.form,
        "contact_type",
        this.contactTypes.find(item => item.id == contact_type_id)
      );
    },

    /**
     * Valida o campo de texto de acordo com o padrão definido
     * Possíveis validações:
     * - url
     * - email
     * - regex
     */
    isValidValue(value) {
      const type = this.form.contact_type || {};

      // Se não tiver regra definida, pule
      if (!type.rule) {
        return true;
      }

      //
      if (type.rule === "email") {
        if (!isEmail(value)) return "E-mail inválido";
      }

      //
      else if (type.rule === "url") {
        if (!isUrl(value)) return "URL inválida";
      }
      //
      else if (type.rule.startsWith("regex:")) {
        const [, regex] = type.rule.split(":");

        if (!new RegExp(regex).test(value)) {
          return "Não está no formato válido";
        }
      }

      return true;
    },

    /*******************************************************
     *
     *  FUNÇÕES BÁSICAS DE QUALQUER DIALOG
     *
     *******************************************************/

    /**
     * Administrador edita os dados
     */
    openToEdit(data) {
      this.mode = "edit";
      this.title = `Editar "${data.description}"`;
      this.edit(data);
    },

    /**
     * Administrador adiciona os dados
     */
    openToAdd(personId) {
      this.mode = "add";
      this.title = "Cadastrar Novo Contato";
      this.clear();
      this.open();
      this.form.person_id = personId;
    },

    /**
     * Administrador cadastra mas não salva, apenas retorna
     */
    openToGetData() {
      this.clear();
      this.open();
      this.mode = "get";
      this.title = "Cadastrar Novo Contato";
      this.form.person_id = null;
    },

    /**
     * Abre o dialog preparado para alteerar os dados
     */
    edit(payload) {
      // Mescla os dados recebidos para não alterar o que está para trás
      this.form = { ...payload };

      // Abre por último, depois que está tudo preenchido
      this.open();
    },

    /**
     * Abre o dialog
     */
    open() {
      this.visible = true;
    },

    /**
     * Fecha o dialog
     */
    close() {
      this.visible = false;
      // Atrasando um pouco a limpeza dos dados para
      // não exibir os dados ficando apagados enquanto
      // fecho o dialog
      setTimeout(() => {
        this.clear();
        this.$refs.form.resetValidation();
      }, 100);
    },

    /**
     * Limpa os dados do dialogLimpa os dados do dialog
     */
    clear() {
      this.form = {};
      this.errors = {};
    }
  },

  mounted() {
    this.$store.dispatch("contact_types/all");
  }
};
</script>

<style>
</style>
