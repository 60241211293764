<template>
  <div>
    <v-list-item two-line @click.stop="handleClick">
      <v-list-item-content>
        <v-list-item-title>{{ description }}</v-list-item-title>
        <v-list-item-subtitle>
          <KChip>{{ contact_type.name }}</KChip>
          {{ value }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-row>
          <v-btn v-if="showEdit" @click.stop="handleEditClick" icon>
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn @click.stop="handleDeleteClick" icon>
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-row>
      </v-list-item-action>
    </v-list-item>
    <v-divider></v-divider>
  </div>
</template>

<script>
import KChip from "@/components/KChip";
export default {
  name: "contact-list-item",

  components: {
    KChip
  },

  props: {
    description: String,
    id: Number,
    value: String,
    showEdit: Boolean,
    contact_type: { type: Object, default: () => ({}) }
  },

  methods: {
    handleClick() {
      this.$emit("click:item");
    },
    handleEditClick() {
      this.$emit("click:edit");
    },
    handleDeleteClick() {
      this.$emit("click:delete");
    }
  }
};
</script>

<style>
</style>
