<template>
  <KDialog
    :visible="visible"
    :loading="loading"
    :title="title"
    :actions="dialogActions"
    @click:close="close"
    @click:add="save"
    @click:edit="save"
    @click:insert="insert"
  >
    <!-- Conteúdo -->
    <KForm ref="form">
      <v-container>
        <v-row class="my-4">
          <!-- Descrição -->
          <v-col cols="12" class="">
            <v-text-field
              outlined
              dense
              :rules="[$validation.isRequired]"
              label="Descrição"
              hint="Defina um nome para este endereço"
              persistent-hint
              :error-messages="errors.description"
              v-model="form.description"
              required
            ></v-text-field>
          </v-col>

          <!-- CEP -->
          <v-col cols="12" sm="6" class="">
            <ZipCodeField
              outlined
              dense
              :rules="[$validation.isRequired]"
              label="CEP"
              v-model="form.zipcode"
              :errorMessages="errors.zipcode"
              @loaded="onZipcodeLoaded"
            ></ZipCodeField>
          </v-col>

          <!-- Rua -->
          <v-col cols="12" class="">
            <v-text-field
              outlined
              dense
              :rules="[$validation.isRequired]"
              label="Rua"
              persistent-hint
              :error-messages="errors.street"
              v-model="form.street"
              required
            ></v-text-field>
          </v-col>

          <!-- Número -->
          <v-col cols="12" sm="6" class="">
            <v-text-field
              outlined
              dense
              :rules="[$validation.isRequired]"
              label="Número"
              persistent-hint
              :error-messages="errors.number"
              v-model="form.number"
              required
            ></v-text-field>
          </v-col>

          <!-- Bairro -->
          <v-col cols="12" sm="6" class="">
            <v-text-field
              outlined
              dense
              :rules="[$validation.isRequired]"
              label="Bairro"
              persistent-hint
              :error-messages="errors.district"
              v-model="form.district"
              required
            ></v-text-field>
          </v-col>

          <!-- Complemento -->
          <v-col cols="12" class="">
            <v-text-field
              outlined
              dense
              label="Complemento"
              persistent-hint
              :error-messages="errors.complement"
              v-model="form.complement"
              required
            ></v-text-field>
          </v-col>

          <!-- Cidade -->
          <v-col cols="12" sm="6" class="">
            <v-text-field
              outlined
              dense
              v-model="form.city"
              :rules="[$validation.isRequired]"
              label="Cidade"
              readonly
              persistent-hint
              :error-messages="errors.city"
              required
            ></v-text-field>
          </v-col>
          <!-- Estado -->
          <v-col cols="12" sm="6" class="">
            <v-text-field
              outlined
              dense
              v-model="form.state"
              :rules="[$validation.isRequired]"
              label="UF"
              readonly
              persistent-hint
              :error-messages="errors.state"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" class="pa-0 ma-0"></v-col>

          <!-- Latitude -->
          <v-col cols="12" sm="6" class="">
            <v-text-field
              outlined
              dense
              :rules="[$validation.isLatitude]"
              label="Latitude"
              persistent-hint
              :error-messages="errors.latitude"
              v-model="form.latitude"
              required
            ></v-text-field>
          </v-col>

          <!-- Longitude -->
          <v-col cols="12" sm="6" class="">
            <v-text-field
              outlined
              dense
              :rules="[$validation.isLongitude]"
              label="Longitude"
              persistent-hint
              :error-messages="errors.longitude"
              v-model="form.longitude"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </KForm>
  </KDialog>
</template>

<script>
import KForm from "@/components/KForm";
import KDialog from "@/components/KDialog";
import KInputMasked from "@/components/KInput/Masked";
import ZipCodeField from "@/components/ZipCodeField/index.vue";

export default {
  components: {
    KForm,
    KDialog,
    KInputMasked,
    ZipCodeField
  },

  data() {
    return {
      visible: false,
      form: {},
      title: "Cadastrar Endereço",
      loading: false,
      mode: null,
      errors: {}
    };
  },
  watch: {},

  computed: {
    dialogActions() {
      switch (this.mode) {
        case "add":
          return [{ label: "Cadastrar", eventName: "click:add" }];

        case "edit":
          return [{ label: "Salvar Alterações", eventName: "click:edit" }];

        case "get":
          return [{ label: "Inserir", eventName: "click:insert" }];
      }
    }
  },

  methods: {
    /**
     * Devolve os dados para a página pai sem salvar
     */
    insert() {
      if (this.$refs["form"].validate()) {
        this.$emit("click:insert", this.form);
        this.clear();
        this.$refs["form"].resetValidation();
        this.close();
      }
    },

    /**
     * Salva os dados do endereço no banco de dados e devolve o evento
     * para o pai atualizar o store
     */
    async save() {
      try {
        this.errors = {};

        if (this.$refs["form"].validate()) {
          this.loading = true;

          if (this.mode === "edit") {
            const address = await this.$api.put(
              `/people/${this.form.person_id}/addresses/${this.form.id}`,
              this.form
            );

            this.$emit("click:edit", address.data.data);
          } else if (this.mode === "add") {
            const address = await this.$api.post(
              `/people/${this.form.person_id}/addresses`,
              this.form
            );
            this.$emit("click:add", address.data.data);
          }

          this.$message.success("Dados salvos com sucesso");
          this.loading = false;
          this.close();
        }
      } catch (error) {
        this.errors = this.$message.serverError(error);
        this.loading = false;
      }
    },

    /**
     * Quando o cep for preenchido
     */
    onZipcodeLoaded(data) {
      this.$set(this.form, "street", data.street);
      this.$set(this.form, "number", data.number);
      this.$set(this.form, "district", data.district);
      this.$set(this.form, "complement", data.complement);
      this.$set(this.form, "city", data.city);
      this.$set(this.form, "state", data.state);
    },

    /*******************************************************
     *
     *  FUNÇÕES BÁSICAS DE QUALQUER DIALOG
     *
     *******************************************************/

    /**
     * Administrador edita os dados
     */
    openToEdit(data) {
      this.mode = "edit";
      this.title = `Editar "${data.description}"`;
      this.edit(data);
    },

    /**
     * Administrador adiciona os dados
     */
    openToAdd(personId) {
      this.mode = "add";
      this.title = "Cadastrar Novo Endereço";
      this.clear();
      this.open();
      this.form.person_id = personId;
    },

    /**
     * Administrador cadastra mas não salva, apenas retorna
     */
    openToGetData() {
      this.clear();
      this.open();
      this.mode = "get";
      this.title = "Cadastrar Novo Endereço";
      this.form.person_id = null;
    },

    /**
     * Abre o dialog preparado para alteerar os dados
     */
    edit(payload) {
      // Mescla os dados recebidos para não alterar o que está para trás
      this.form = { ...payload };

      // Abre por último, depois que está tudo preenchido
      this.open();
    },

    /**
     * Abre o dialog
     */
    open() {
      this.visible = true;
    },

    /**
     * Fecha o dialog
     */
    close() {
      this.visible = false;
      // Atrasando um pouco a limpeza dos dados para
      // não exibir os dados ficando apagados enquanto
      // fecho o dialog
      setTimeout(() => {
        this.clear();
        this.$refs.form.resetValidation();
      }, 100);
    },

    /**
     * Limpa os dados do dialogLimpa os dados do dialog
     */
    clear() {
      this.form = {};
      this.errors = {};
    }
  }
};
</script>

<style>
</style>
