<template>
  <v-row class="mx-0 flex-nowrap">
    <v-tooltip bottom v-for="action in actions" :key="action.label">
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on" @click.stop.prevent="action.click">
          <!-- Exibição do badge -->
          <v-badge
            color="red darken-3"
            :value="!!action.count"
            bordered
            dot
            overlap
          >
            <v-icon>{{ action.icon }}</v-icon>
          </v-badge>
        </v-btn>
      </template>
      {{ action.label }}
    </v-tooltip>
  </v-row>
</template>

<script>
export default {
  name: "UserActions",
  props: {
    person: Object
  },

  computed: {
    actions() {
      const logsCount =
        this.person &&
        this.person.user &&
        this.person.user.__meta__ &&
        this.person.user.__meta__.logs_count;

      const list = [];

      // Se for usuário
      if (this.person && this.person.user) {
        // Ver logs
        list.push({
          label: "Ver logs deste usuário",
          icon: "mdi-account-details",
          click: () => this.$emit("click:view_logs"),
          count: logsCount
        });
        // Resetar senha
        list.push({
          label: "Resetar senha deste usuário",
          icon: "mdi-account-key",
          click: () => this.$emit("click:reset_password")
        });

        // Alterar Usuário
        list.push({
          label: "Alterar informações do usuário",
          icon: "mdi-account-edit",
          click: () => this.$emit("click:edit_user")
        });
      }
      // Se não for usuário
      else {
        list.push({
          label: "Criar usuário",
          icon: "mdi-account-plus",
          click: () => this.$emit("click:add_user")
        });
      }

      return list;
    }
  }
};
</script>

<style>
</style>
