<template>
  <KChip v-if="person.legal_nature === 'F'" color="orange">Pessoa Física</KChip>
  <KChip v-else-if="person.legal_nature === 'J'" color="purple"
    >Pessoa Jurídica</KChip
  >
</template>

<script>
import KChip from "@/components/KChip";
export default {
  components: {
    KChip
  },
  props: { person: Object }
};
</script>

<style>
</style>
