<template>
  <KDialog
    max-width="360"
    :visible="visible"
    :loading="loading"
    :title="title"
    :actions="dialogActions"
    @click:close="close"
    @click:add="save"
    @click:edit="save"
  >
    <KForm>
      <v-row class="mt-3">
        <!--  -->
        <v-col cols="12">
          <v-select
            v-bind="input"
            label="Selecione o e-mail"
            :items="emails"
            :error-messages="errors.email"
            v-model="form.email"
          ></v-select>
        </v-col>

        <template v-if="mode === 'add'">
          <!--  -->
          <v-col cols="12">
            <v-password
              v-bind="input"
              label="Senha"
              :error-messages="errors.password"
              v-model="form.password"
            ></v-password>
          </v-col>

          <!--  -->
          <v-col cols="12">
            <v-password
              v-bind="input"
              label="Redigite a Senha"
              :error-messages="errors.password_confirmation"
              v-model="form.password_confirmation"
            ></v-password>
          </v-col>
        </template>

        <template v-if="mode === 'edit'">
          <!--  -->
          <v-col cols="12">
            <v-switch
              inset
              :true-value="1"
              :false-value="0"
              label="Usuário está ativo?"
              :error-messages="errors.is_active"
              v-model="form.is_active"
            ></v-switch>
          </v-col>
        </template>

        <!-- Permissões -->
        <v-col cols="12">
          <RolesSelector
            multiple
            label="Permissões"
            :max-chips="2"
            v-bind="input"
            :error-messages="errors.roles"
            v-model="form.roles"
          ></RolesSelector>
        </v-col>
      </v-row>
    </KForm>
  </KDialog>
</template>

<script>
import KChip from "@/components/KChip";
import KForm from "@/components/KForm";
import KAlert from "@/components/KAlert";
import KDialog from "@/components/KDialog";
import VPassword from "@/components/VPassword";

import RolesSelector from "../_shared/RolesSelector";

export default {
  name: "dialog-user",

  components: {
    KForm,
    KChip,
    KAlert,
    KDialog,
    VPassword,

    RolesSelector
  },

  data() {
    return {
      // dialog
      visible: false,
      loading: false,
      title: null,
      // dados
      form: {},
      emails: [],
      errors: {},
      //
      mode: null,
      //
      input: { dense: true, outlined: true }
    };
  },

  computed: {
    dialogActions() {
      if (this.mode === "edit") {
        return [{ label: "Salvar alterações", eventName: "click:edit" }];
      } else if (this.mode === "add") {
        return [{ label: "Criar Usuário", eventName: "click:add" }];
      }
    },

    payload() {
      const roles =
        (this.form.roles && this.form.roles.map(role => role.slug)) || [];

      const common = {
        email: this.form.email,
        roles
      };

      if (this.mode === "add") {
        return {
          ...common,
          person_id: this.form.person_id,
          password: this.form.password,
          password_confirmation: this.form.password_confirmation
        };
      } else if (this.mode === "edit") {
        return {
          ...common,
          roles: !this.form.is_active ? [] : roles,
          user_id: this.form.user_id,
          is_active: this.form.is_active
        };
      }
    }
  },

  methods: {
    /**
     *
     *
     */
    async save() {
      try {
        this.$loading.show();
        this.loading = true;

        //
        await this.$store.dispatch("people/handle_user", {
          payload: this.payload,
          mode: this.mode
        });

        this.$loading.hide();
        this.loading = false;
        this.close();
      } catch (error) {
        this.$loading.hide();
        this.loading = false;
        this.errors = this.$message.serverError(error);
      }
    },

    /**
     *
     *
     */
    openToEdit(payload = {}) {
      try {
        const {
          name,
          user: { id, email, is_active }
        } = payload;

        this.form = {
          user_id: id, // id do usuário, não da pessoa
          name,
          email,
          is_active,
          roles: payload.user.roles
        };
        this.emails = payload.emails;

        this.mode = "edit";
        this.title = `Usuário ${payload.name}`;
        this.visible = true;
      } catch (error) {
        console.log(error);
        this.$message.serverError(error);
      }
    },

    /**
     *
     *
     */
    openToAdd(payload) {
      try {
        this.form = {
          person_id: payload.id,
          email: null,
          roles: [],
          password: null,
          password_confirmation: null
        };
        this.emails = payload.emails;

        this.mode = "add";
        this.title = `Criar Usuário ${payload.name}`;
        this.visible = true;
      } catch (error) {
        this.$message.serverError(error);
      }
    },

    /**
     *
     *
     */
    close() {
      try {
        this.form = {};
        this.emails = [];
        this.visible = false;
      } catch (error) {
        this.$message.serverError(error);
      }
    }
  }
};
</script>

<style>
</style>
