<template>
  <!-- Cabeçalho -->
  <v-row class="mx-0">
    <!-- Lista de condomínios -->
    <v-col cols="12" class="py-0 px-2"> </v-col>
    <v-col cols="12" class="py-0 px-2">
      <v-card outlined class="rounded-lg">
        <v-data-table
          class="has-click"
          item-key="id"
          no-data-text="Nenhuma pessoa encontrada"
          no-results-text="Nenhuma pessoa encontrada"
          outlined
          disable-sort
          disable-filtering
          :footer-props="{ 'items-per-page-options': [20, 50, 100] }"
          :items-per-page="20"
          :loading="loading"
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="pagination.total"
          @update:options="onChangeOptions"
          @click:row="(item) => $emit('click:row', item)"
        >
          <!-- eslint-disable vue/valid-v-slot  -->
          <template v-slot:item.id="{ item }">
            <p class="my-0">{{ item.id }}</p>
          </template>

          <!-- eslint-disable vue/valid-v-slot  -->
          <template v-slot:item.cpf_cnpj="{ item }">
            <span v-if="item.cpf" class="caption">{{
              item.cpf | mask("000.000.000-00")
            }}</span>
            <span v-else-if="item.cnpj" class="caption">{{
              item.cnpj | mask("00.000.000/0000-00")
            }}</span>
          </template>

          <!-- eslint-disable vue/valid-v-slot  -->
          <template v-slot:item.name="{ item }">
            <span class="caption">{{ item.name }}</span>
          </template>

          <!-- eslint-disable vue/valid-v-slot  -->
          <template v-slot:item.user_avatar="{ item }">
            <k-avatar
              :size="36"
              :picture="item.user && item.user.avatar && item.user.avatar.url"
              :name="item.name"
              :timeout="0"
            ></k-avatar>
          </template>

          <!-- eslint-disable vue/valid-v-slot  -->
          <template v-slot:item.legal_nature="{ item }">
            <PersonLegalNature :person="item" />
          </template>

          <!-- eslint-disable vue/valid-v-slot  -->
          <template v-slot:item.permissions="{ item }">
            <PersonRoles :person="item" />
          </template>

          <!-- eslint-disable vue/valid-v-slot  -->
          <template v-slot:item.actions="{ item }">
            <PersonActions
              :person="item"
              @edit="edit('click:edit', item)"
              @click:reset_password="$emit('click:reset_password', item)"
              @click:view_logs="$emit('click:view_logs', item)"
              @click:add_user="$emit('click:add_user', item)"
              @click:edit_user="$emit('click:edit_user', item)"
            />
          </template>
        </v-data-table>
      </v-card>
    </v-col>

    <!-- Espaçamento -->
    <div style="height: 64px; width: 100%"></div>
  </v-row>
</template>

<script>
import KChip from "@/components/KChip";
import KAvatar from "@/components/KAvatar";
import PersonActions from "../_shared/PersonActions";
import PersonLegalNature from "../_shared/PersonLegalNature";
import PersonRoles from "../_shared/PersonRoles";

export default {
  components: { KChip, KAvatar, PersonActions, PersonLegalNature, PersonRoles },

  props: {
    items: {
      type: Array,
      default: () => []
    },
    pagination: {
      type: Object,
      default: () => ({ page: 1, total: 0, perPage: 20, lastPage: 1 })
    },
    loading: Boolean
  },

  watch: {
    pagination(value) {
      this.options = {
        ...this.options,
        page: this.pagination.page,
        itemsPerPage: this.pagination.perPage
      };
    }
  },

  data() {
    return {
      headers: [
        { text: "ID", value: "user_avatar", sortable: false, width: 40 },
        { text: "", value: "id", sortable: false, width: 40 },
        { text: "CPF/CNPJ", sortable: false, value: "cpf_cnpj" },
        { text: "Nome", sortable: false, value: "name" },
        { text: "Natureza Legal", sortable: false, value: "legal_nature" },
        { text: "Permissões", sortable: false, value: "permissions" },
        { text: "Ações", value: "actions", sortable: false }
      ],
      options: {}
    };
  },

  methods: {
    onChangeOptions({ itemsPerPage, page }) {
      this.$emit("click:change-pagination", { limit: itemsPerPage, page });
    }
  }
};
</script>

<style >
.has-click.v-data-table tr {
  cursor: pointer;
}
</style>
