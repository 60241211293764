<template>
  <v-card outlined>
    <v-list class="py-0">
      <address-list-item
        v-for="(item, i) in items"
        v-bind="item"
        :key="item.description"
        :showEdit="showEdit"
        @click:item="onEditClick(i, item)"
        @click:edit="onEditClick(i, item)"
        @click:delete="onDeleteClick(i, item)"
      ></address-list-item>
    </v-list>
  </v-card>
</template>

<script>
import AddressListItem from "./item.vue";

export default {
  name: "address-list",
  components: {
    AddressListItem
  },
  props: {
    items: { type: Array, default: () => [] },
    showEdit: Boolean
  },
  data: () => ({}),

  methods: {
    onEditClick(index, item) {
      this.$emit("click:edit", { index, item });
    },
    onDeleteClick(index, item) {
      this.$emit("click:delete", { index, item });
    }
  }
};
</script>

<style>
</style>
