<template>
  <KDialog
    :visible="visible"
    :loading="loading"
    :title="title"
    @click:close="close"
    max-width="360"
  >
    <KForm ref="form">
      <v-row class="my-2">
        <v-col cols="12">
          <!-- Senha -->
          <KAlert info title="Informação">
            A nova senha deste usuário será gerada aleatoriamente e exibida
            abaixo.
          </KAlert>
        </v-col>

        <v-col cols="12">
          <v-switch
            class="ma-0"
            hide-details
            v-model="data.send_email"
            color="primary"
            inset
            label="Enviar nova senha por e-mail"
          ></v-switch>
        </v-col>
        <v-col cols="12" align="center">
          <v-btn @click="save" text rounded>Redefinir senha</v-btn>
        </v-col>

        <!-- Mostra a senha -->
        <v-expand-transition>
          <v-col cols="12" v-show="!!data.password && !loading" align="center">
            <v-chip large width="300" outlined color="primary">
              Nova senha:
              <input type="text" id="selectable" :value="data.password" />

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" icon v-on="on" @click.stop="copy">
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                </template>
                <span>Copiar para a área de transferência</span>
              </v-tooltip>
            </v-chip>
            <!-- <v-static label="Nova Senha"></v-static> -->
          </v-col>
        </v-expand-transition>

        <!-- Mensage -->
        <v-expand-transition>
          <v-col sm="12" v-show="state.message" align="center">
            <k-alert dense success>{{ state.message }}</k-alert>
          </v-col>
        </v-expand-transition>
      </v-row>
    </KForm>
  </KDialog>
</template>


  




<script>
// Componentes
import KForm from "@/components/KForm";
import KAlert from "@/components/KAlert";
import KDialog from "@/components/KDialog";
import VStatic from "@/components/VStatic";
import VPassword from "@/components/VPassword";

//
export default {
  components: {
    KForm,
    KAlert,
    VStatic,
    KDialog,
    VPassword
  },

  data() {
    return {
      // Dialog
      visible: false,
      loading: false,
      title: null,
      // Armazena os dados que virão via função para preencher
      form: {},
      data: {
        password: null,
        send_email: false
      },

      errors: {},
      //
      state: {
        message: null
      }
    };
  },

  computed: {},

  methods: {
    /*******************************************************
     *
     *  FUNÇÕES PARA SALVAR E EDITAR OS DADOS NO STORE
     *
     *******************************************************/

    /**
     * Salva os dados de um novo usuário no Store
     */
    async save() {
      try {
        // this.clearServerErrors();
        this.errors = {};

        // Se as demais validações passaram
        this.loading = true;

        const result = await this.$store.dispatch("people/adminResetPassword", {
          user_id: this.form.user_id,
          send_email: this.data.send_email
        });
        // console.log("resultado", result);
        this.data.password = result.password;

        this.$message.success("Senha redefinida com sucesso");
      } catch (error) {
        // this.data = {}
        this.errors = this.$message.serverError(error);
      } finally {
        this.loading = false;
      }
    },

    /*******************************************************
     *
     *  FUNÇÕES BÁSICAS DE QUALQUER DIALOG
     *
     *******************************************************/

    /**
     * Abre o dialog
     */
    open(payload = {}) {
      this.visible = true;
      this.title = `Redefinir senha de ${payload.name}`;
      this.form = { name: payload.name, user_id: payload.user.id };
    },

    /**
     * Fecha o dialog
     */
    close() {
      this.visible = false;
      this.title = null;
      this.clear();
    },

    /**
     * Limpa os dados do dialog
     */
    clear() {
      this.form = {};
      this.data.password = null;
      this.data.send_email = false;
      this.errors = {};
    },

    copy() {
      let testingCodeToCopy = document.querySelector("#selectable");
      testingCodeToCopy.select();

      try {
        const successful = document.execCommand("copy");

        if (successful) {
          this.setMessage("Senha copiada para a área de transferência");
        } else {
          this.$message.error(
            "Erro ao copiar senha para a área de transferência"
          );
        }
      } catch (err) {
        this.$message.error(
          "Erro ao copiar senha para a área de transferência"
        );
      }

      // window.getSelection().removeAllRanges();
    },

    setMessage(text, timeout = 2000) {
      this.state.message = text;
      setTimeout(() => (this.state.message = null), timeout);
    }
  }
};
</script>

<style scoped>
#selectable {
  margin: 0 16px;
  font-size: 24px;
  cursor: text;
  width: 100px;
  text-align: center;
}
</style>
